<template>
    <div class="header-box" :class="scrollTop > 0 ? 'panel-bg' : ''">
        <div class="panel" :class="scrollTop > 0 ? 'panel-bg' : ''">
            <div class="header">
                <img class="logo" src="@/assets/logo.png"/>

                <div class="menu">
                    <div :class="['item', activeIndex === 1 ? 'active' : '']" @click="open(0)">HOME</div>
                    <div :class="['item', activeIndex === 2 ? 'active' : '']" @click="open(1)">ABOUT US</div>
                    <div :class="['item', activeIndex === 3 ? 'active' : '']" @click="open(2)">TEAM</div>
                </div>

                <img @click="openDialog" class="button" src="@/assets/contact-button.png">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['scrollTop'],
        data: () => ({
            urlList: [
                {
                    index: 1,
                    path: "/home"
                },
                {
                    index: 2,
                    path: "/about"
                },
                {
                    index: 3,
                    path: "/team"
                }
            ],
            activeIndex: 1
        }),
        watch: {
            $route(to) {
                this.urlList.forEach(item => {
                    if(to.path.indexOf(item.path) > -1) {
                        this.activeIndex = item.index
                    }
                })
            }
        },
        methods: {
            open(listIndex) {
                this.activeIndex = this.urlList[listIndex].index
                this.$router.push(this.urlList[listIndex].path)
            },
            openDialog() {
                this.$emit("open")
            }
        }
    }
</script>

<style scoped lang="scss">
.header-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    z-index: 100;
}
.panel {
    position: absolute;
    top: 0;
    left: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 1440px;
    transform: translate(-50%, 0);
    z-index: 100;
}
.panel-bg {
    background-color: #050505;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1352px;
    height: 72px;
}

.logo {
    height: 46px;
}

.button {
    height: 32px;
    &:hover {
        cursor: pointer;
        opacity: .8;
    }
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 258px;

    > .item {
        display: flex;
        align-items: center;

        position: relative;

        font-size: 18px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.4);
        line-height: 32px;

        &:hover {
            cursor: pointer;
            color: #FFFFFF;
        }

        &.active {
            color: #FFFFFF;
        }
    }
}
</style>