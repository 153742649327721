<template>
  <div class="body-flex">
      <div class="max-body">
          <div :class="['show-hidden', visible ? 'header-ab' : '', !!!$route.meta.isDisplay ? 'show' : '']">
            <header-base v-if="!!!$route.meta.isDisplay" :scroll-top="scrollTop" @open="openDialog"/>
          </div>

          <transition name="page-fade" mode="out-in">
              <router-view/>
          </transition>

          <div :class="['show-hidden', !!!$route.meta.isDisplay ? 'show' : '']">
            <footer-base v-if="!!!$route.meta.isDisplay" @open="openDialog"/>
          </div>
      </div>

      <contact-dialog :dialog-visible.sync="visible"/>
  </div>
</template>

<script>
import HeaderBase from "./views/header";
import FooterBase from "./views/footer";
import ContactDialog from "./components/ContactDialog";

    export default {
        components: {
            HeaderBase,
            FooterBase,
            ContactDialog
        },
        data: () => ({
            scrollTop: 0,
            visible: false
        }),
        methods: {
            handleScroll() {
                this.scrollTop = document.documentElement.scrollTop;
            },
            openDialog() {
                this.visible = true;
            }
        },
        mounted() {
            // 取消开始的loading动画
            const preLoader = document.querySelector('#pre-loader')
            preLoader.style.display = 'none'

            document.body.ondrop = event => {
                event.preventDefault()
                event.stopPropagation()
            }

            document.addEventListener("scroll", this.handleScroll)
        }
    }
</script>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /*user-select: none;*/
    font-family: Instrument Sans;
}

.show-hidden {
    display: none;
}

.show {
    display: flex;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
}

.body-flex {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    background-color: #000000;
}

.max-body {
    position: relative;
    width: 1440px;
    background-color: #050505;
}
/* 其余 */
.max-width {
    width: 100%;
    max-width: 1440px;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}


.banner {
    position: relative;
    display: flex;
    width: 100%;
}

.banner-item-box {
    display: flex;
    width: 100%;

    > img {
        width: 100%;
        height: 460px;
    }
}

.header-ab .panel {
    left: calc(50% - 8px);
}
</style>
