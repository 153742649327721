<template>
    <div class="footer-bg">
        <div class="f-logo">
            <img src="@/assets/footer-logo.png"/>
        </div>
        <div class="f-text">
            <div class="text-top">
                <div class="item" @click="getActiveHref('/home')">HOME</div>
                <div class="item" @click="getActiveHref('/about')">ABOUT US</div>
                <div class="item" @click="getActiveHref('/team')">TEAM</div>
                <div class="item" @click="openDialog">CONTACT US</div>
            </div>
        </div>
        <div class="bottom-text">© Copyright 2024 - All Rights Reserved</div>
    </div>
</template>

<script>
    export default {
        methods: {
            getActiveHref(path) {
                this.$router.push(path);
            },
            openDialog() {
                this.$emit("open")
            }
        }
    }
</script>

<style scoped lang="scss">
.footer-bg {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 256px;
    padding-top: 50px;
    background-image:  linear-gradient(50deg,rgba(18, 18, 65, 1) 0%,rgba(18, 18, 65, 1) 45%, rgba(37, 69, 135, 1) 100%);
    background-repeat: repeat;

    .bottom-text {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        color: #FFFFFF;
    }
}

.f-logo {
    display: flex;
    position: relative;
    margin-bottom: 18px;

    > img {
        width: 191px;
        height: 60px;
    }
}

.f-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 466px;

    .text-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 40px;

        > .item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            color: #FFFFFF;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }
    }
}
</style>