<template>
    <div class="home">
        <div class="banner">
            <img src="@/assets/home-banner-text.png" class="banner-text">
            <img src="@/assets/home-banner.png" class="banner-img"/>
        </div>

        <div class="home-box">
            <div class="point message-box">
                <div class="title">
                    <span>Industry Pain Points</span>
                    <img src="@/assets/star.png"/>
                </div>

                <div class="point-panel">
                    <div class="item">
                        <img src="@/assets/1.png" class="img-number"/>
                        <div class="subtitle">Demand Fluctuations</div>
                        <div class="note">Inability to accurately forecast market demand, leading to supply chain disruptions or inventory backlogs.</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/2.png" class="img-number"/>
                        <div class="subtitle">Lengthy Supply Chain</div>
                        <div class="note">Low collaboration efficiency among suppliers, manufacturers, and retailers, impacting overall supply chain efficiency.</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/3.png" class="img-number"/>
                        <div class="subtitle">Complex Inventory Management</div>
                        <div class="note">Inability to dynamically adjust inventory levels, resulting in high inventory management costs.</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/4.png" class="img-number"/>
                        <div class="subtitle">Rising Transportation Costs</div>
                        <div class="note">Difficulty in controlling logistics costs, particularly in global transport, where fluctuations are common.</div>
                    </div>
                </div>
            </div>
            <div class="core message-box">
                <div class="title">
                    <span>Core Functions</span>
                    <img src="@/assets/star.png"/>
                </div>

                <div class="core-panel">
                    <div class="item">
                        <div class="item-child">
                            <img src="@/assets/demand.png"/>
                            <div class="subtitle">Demand Forecasting</div>
                            <div class="note">Utilizing historical sales data, market trends, social media data, and weather information through machine learning and deep learning models to provide accurate demand forecasting, reducing uncertainty in the supply chain.</div>
                        </div>
                        <div class="item-child">
                            <img src="@/assets/transportation.png"/>
                            <div class="subtitle">Transportation Optimization</div>
                            <div class="note">Using AI for route optimization, considering real-time traffic, weather, and fuel prices to dynamically adjust logistics routes, lowering transportation costs and time.</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-child">
                            <img src="@/assets/inventory.png"/>
                            <div class="subtitle">Inventory Optimization</div>
                            <div class="note">AI monitors inventory levels in real time and automatically adjusts based on demand forecasts, preventing inventory backlog or stockouts. It supports multi-warehouse management to ensure balanced inventory and optimal logistics paths.</div>
                        </div>
                        <div class="item-child">
                            <img src="@/assets/management.png"/>
                            <div class="subtitle">Supply Chain Risk Management</div>
                            <div class="note">Analyzing global supply chain dynamics in real time to identify potential risks in advance and provide mitigation strategies, ensuring the stable operation of the supply chain.</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-child">
                            <img src="@/assets/supply.png"/>
                            <div class="subtitle">Supply Chain Automation</div>
                            <div class="note">Optimizing procurement planning, supplier collaboration, and logistics paths through AI. The platform dynamically adjusts supply chain processes based on demand and supply conditions, reducing resource wastage and time delays.</div>
                        </div>
                        <div class="item-child">
                            <img src="@/assets/statistics.png"/>
                            <div class="subtitle">Visual Monitoring and Analysis</div>
                            <div class="note">Providing a visual monitoring system for the entire process, allowing real-time observation of supply chain operations and helping businesses identify and optimize inefficient areas.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="business message-box">
                <div class="title">
                    <span>Business Model</span>
                    <img src="@/assets/star.png"/>
                </div>

                <div class="business-panel">
                    <img src="@/assets/business-img.png" class="business-img"/>
                    <div class="info">
                        <div class="info-item">
                            <img src="@/assets/star.png" class="star"/>
                            <div class="info-title">Subscription Model</div>
                            <div class="info-note">Monthly or annual fees based on usage, data processing volume, and service levels.</div>
                        </div>
                        <div class="info-item">
                            <img src="@/assets/star.png" class="star"/>
                            <div class="info-title">Implementation and Training Services</div>
                            <div class="info-note">Providing customized supply chain optimization solutions for enterprises, including deployment and training.</div>
                        </div>
                        <div class="info-item">
                            <img src="@/assets/star.png" class="star"/>
                            <div class="info-title">Data-Driven Consulting</div>
                            <div class="info-note">·Offering additional strategic consulting services based on data reports and trend analyses generated by the platform to help optimize decision-making.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="customers message-box">
                <div class="title">
                    <span>Target Customers</span>
                    <img src="@/assets/star.png"/>
                </div>

                <div class="customers-panel">
                    <div class="item">
                        <img src="@/assets/customer-1.png"/>
                        <div class="subtitle">Medium to Large Manufacturing Enterprises</div>
                        <div class="note">Companies needing to manage complex supply chains, particularly those with multiple production plants and warehousing facilities.</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/customer-2.png"/>
                        <div class="subtitle">Retail Enterprises</div>
                        <div class="note">Multi-channel retail companies that require accurate consumer demand forecasting.</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/customer-3.png"/>
                        <div class="subtitle">Logistics Companies</div>
                        <div class="note">Providing intelligent route planning and transportation management services to improve efficiency.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="market-box message-box">
            <div class="title">
                <span>Market Potential</span>
                <img src="@/assets/star.png"/>
            </div>

            <div class="message">The global supply chain management market is rapidly growing, and with the acceleration of globalization and e-commerce, the demand for intelligent supply chain solutions is increasing. The AI Intelligent Supply Chain Optimization Platform will help enterprises achieve efficient, low-cost supply chain management, enhancing their competitiveness.</div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({

    })
}
</script>

<style scoped lang="scss">
.message-box {
    width: 100%;

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 106px;

        span {
            font-size: 36px;
            line-height: 51px;
            color: #FFFFFF;
        }

        > img {
            width: 24px;
            height: 24px;
            margin-left: 10px;
        }
    }
}

.home-box {
    width: 100%;
    height: 3129px;

    background: url("../assets/home-bg.png") 100% no-repeat;

    .point {
        padding: 148px 0 235px 0;

        .point-panel {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            padding: 0 150px;

            .item {
                position: relative;
                width: 248px;
                height: 264px;
                background: url("../assets/border.png") 100% no-repeat;
                padding: 61px 24px 0 24px;

                .img-number {
                    position: absolute;
                    top: -24px;
                    left: 50%;
                    width: 61px;
                    height: 61px;
                    transform: translate(-50%, 0);
                }

                .subtitle {
                    font-size: 16px;
                    line-height: 20px;
                    color: #FFFFFF;
                    margin-bottom: 30px;
                }

                .note {
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                }

                &:nth-child(3) .subtitle,
                &:nth-child(4) .subtitle {
                    margin-bottom: 14px;
                }
            }
        }
    }

    .core {
        .title {
            margin-bottom: 76px;
        }

        .core-panel {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0 175px;
            margin-bottom: 133px;

            .item {
                width: 346px;

                .item-child {
                    width: 100%;
                    height: 276px;
                    padding: 24px;
                    border: 1px solid rgba(255, 255, 255, .1);
                    border-radius: 16px;
                    background: rgba(255, 255, 255, .05);
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    > img {
                        width: 50px;
                        height: 50px;
                    }

                    .subtitle {
                        font-size: 16px;
                        line-height: 20px;
                        color: #FFFFFF;
                        margin: 14px 0;
                    }

                    .note {
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(255, 255, 255, .6);
                    }
                }

                &:nth-child(2) {
                    padding-top: 30px;
                }
            }
        }
    }

    .business {
        .title {
            justify-content: flex-start;
            padding-left: 174px;
            margin-bottom: 72px;
        }

        .business-panel {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 115px 0 111px;
            margin-bottom: 214px;

            .business-img {
                flex: none;
                width: 650px;
                height: 400px;
            }

            .info {
                width: 494px;
                padding: 40px 27px 0 0;

                .star {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 24px;
                    height: 24px;
                }

                &-title {
                    font-size: 16px;
                    line-height: 20px;
                    color: #FFFFFF;
                    margin-bottom: 16px;
                }

                &-note {
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(255, 255, 255, .6);
                }

                &-item {
                    position: relative;
                    padding-top: 2px;
                    padding-left: 51px;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .customers {
        .title {
            justify-content: flex-start;
            padding-left: 174px;
            margin-bottom: 67px;
        }

        .customers-panel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 143px;

            .item {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                z-index: 10;

                position: relative;
                width: 370px;
                height: 476px;
                padding: 34px 28px;

                &:nth-child(2) {
                    padding-bottom: 54px;
                }

                > img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }

                .subtitle {
                    font-size: 16px;
                    line-height: 20px;
                    color: #FFFFFF;
                    margin-bottom: 16px;
                }

                .note {
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(255, 255, 255, .6);
                }
            }
        }
    }
}

.market-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 689px;
    padding-top: 217px;
    background: url("../assets/market.png") 100% no-repeat;

    .title {
        margin-bottom: 47px;

        span {
            font-size: 40px;
            line-height: 57px;
        }
    }

    .message {
        width: 905px;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        text-align: center;
    }
}

.banner-img {
    width: 100%;
    height: 720px;
}

.banner-text {
    position: absolute;
    top: 116px;
    left: 214px;

    height: 136px;
}
</style>
