import { render, staticRenderFns } from "./ContactDialog.vue?vue&type=template&id=855755d2&scoped=true"
import script from "./ContactDialog.vue?vue&type=script&lang=js"
export * from "./ContactDialog.vue?vue&type=script&lang=js"
import style0 from "./ContactDialog.vue?vue&type=style&index=0&id=855755d2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855755d2",
  null
  
)

export default component.exports