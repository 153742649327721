<template>
    <el-dialog
        class="dialog-custom"
        :visible.sync="dialogVisible"
        :show-close="false"
        @closed="updateVisible"
        width="866px">
        <div class="info">
            <img src="@/assets/dialog-close.png" class="close" @click="updateVisible"/>
            <img src="@/assets/dialog-img.png" class="img"/>
            <div class="message">
                <div class="title">
                    <img src="@/assets/contact-us.png"/>
                    <img src="@/assets/star.png"/>
                </div>
                <div class="note">If you would like to learn more about our platform, or if you are a founder looking to collaborate, please feel free to contact us. Let's work together to advance the future of intelligent supply chain management!</div>
                <div class="mark">Contact Information:</div>
                <div class="email">Email: contact@pure-node.com</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['dialogVisible'],
    methods: {
        updateVisible() {
            this.$emit("update:dialogVisible", false);
        }
    }
}
</script>

<style scoped lang="scss">
.dialog-custom {
    /deep/ .el-dialog {
        border-radius: 22px;
        border: 1px solid #6A88FF;
        overflow: hidden;
    }
    /deep/ .el-dialog__header,
    /deep/ .el-dialog__body,
    /deep/ .el-dialog__footer {
        padding: 0;
    }

    .info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        position: relative;
        width: 100%;
        height: 476px;
        z-index: 10;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("../assets/dialog-bg.png") no-repeat;
            z-index: -1;
        }

        .close {
            position: absolute;
            top: 26px;
            right: 26px;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .message {
            flex: 1;
            margin-left: 40px;
            padding-right: 102px;

            .title {
                display: flex;
                align-items: center;
                height: 51px;
                margin-top: 74px;
                margin-bottom: 34px;
                gap: 18px;
            }

            .note {
                font-size: 14px;
                line-height: 20px;
                color: #050505;
            }

            .mark {
                font-size: 14px;
                line-height: 20px;
                color: #050505;
                font-weight: bold;
                margin-top: 24px;
                margin-bottom: 5px;
            }

            .email {
                font-size: 14px;
                line-height: 20px;
                color: #050505;
                text-decoration: underline;
            }
        }
    }
}
</style>